/**
 * Trigger a file download programmatically by creating and utilizing a temporary `<a>` element.
 *
 * @example
 * downloadFile('https://dev.indico.io/my-file.csv')
 */
export function downloadFile(downloadUrl: string, filename?: string): void {
  const linkEl = document.createElement('a');

  linkEl.setAttribute('target', '_blank');
  linkEl.setAttribute('href', downloadUrl);
  linkEl.setAttribute('download', filename ?? '');
  linkEl.style.display = 'none';

  document.body.appendChild(linkEl);

  linkEl.click();

  document.body.removeChild(linkEl);
}

export async function downloadBlobFile(downloadUrl: string, filename?: string): Promise<void> {
  try {
    const response = await fetch(downloadUrl);
    if (!response.ok) throw new Error('Failed to fetch file');

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const linkEl = document.createElement('a');

    linkEl.setAttribute('target', '_blank');
    linkEl.setAttribute('href', blobUrl);
    linkEl.setAttribute('download', filename ?? '');
    linkEl.style.display = 'none';

    document.body.appendChild(linkEl);

    linkEl.click();

    document.body.removeChild(linkEl);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Download failed:', error);
  }
}
