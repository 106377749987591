import React, { useState } from 'react';
import { components } from 'react-select';

import { clipboardUtils } from '@indico-data/utils';

import { Button, Icon, LoadingSpinner, Tooltip } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';
import { ComboboxOption } from '../types';

import { StyledCopyableSelect, StyledSelectContainer } from './CopyableSelect.styles';

type Props = PermafrostComponent & {
  options: ComboboxOption[];
  value: ComboboxOption;
  noOptionsMessage?: string;
  downloadable?: boolean;
  isDownloading?: boolean;
  onChange: (selectedOption: ComboboxOption) => void;
  onDownload?: (args: { variables: { id: any } }) => void;
};

export function CopyableSelect(props: Props) {
  const {
    className,
    options,
    onChange,
    value,
    noOptionsMessage = 'No files found',
    downloadable,
    isDownloading,
    onDownload,
  } = props;
  const [displayCopySuccess, setDisplayCopySuccess] = useState(false);

  const copyHandler = () => {
    clipboardUtils.copy(value.label);
    setDisplayCopySuccess(true);
    setTimeout(() => {
      setDisplayCopySuccess(false);
    }, 2000);
  };

  const ControlComponent = (props: any) => {
    return (
      <components.Control {...props}>
        <>
          <Tooltip id="tempIDd" for="selectFile" block>
            {props.getValue()[0].label}
          </Tooltip>
          <div data-tip data-for="selectFile" className="combobox__flex-control">
            {props.children}
          </div>
        </>
      </components.Control>
    );
  };

  const OptionComponent = ({ ...props }) => {
    const { value, label } = props.data as any;
    const id = `${value}-shortened-filename`;
    return (
      <components.Option {...props}>
        <div className="combobox__flex-option">
          <p data-tip data-for={id} className="combobox__ellipsis">
            {label}
          </p>
          <Tooltip for={id}>
            <p>{label}</p>
          </Tooltip>
        </div>
      </components.Option>
    );
  };

  // By default react-select searches both the label and value, we only want to search the labels as the value is the fileId
  const customFilter = (option: ComboboxOption, searchText: string) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  return (
    <StyledSelectContainer className={className} data-cy={props['data-cy']}>
      <StyledCopyableSelect
        classNamePrefix="combobox"
        options={options}
        onChange={onChange}
        value={value}
        searchable
        noOptionsMessage={() => noOptionsMessage}
        filterOption={customFilter}
        components={{ Option: OptionComponent, Control: ControlComponent }}
      />

      <Button
        className="copy-filepath__button"
        variant="link-style"
        onClick={copyHandler}
        data-for="copy-filename"
        data-tip
      >
        {displayCopySuccess ? (
          <Icon name="check" size={['18px']} className="copy-filepath__check" />
        ) : (
          <Icon name="fa-clipboard" ariaLabel="copy filename" size={['18px']} />
        )}
      </Button>
      <Tooltip
        className="copy-success__tooltip"
        questionMark={false}
        place={'bottom'}
        for="copy-filename"
      >
        {displayCopySuccess ? 'copied!' : 'copy filename'}
      </Tooltip>
      {downloadable && onDownload ? (
        <>
          <Button
            className="download-file__button"
            variant="link-style"
            onClick={() => onDownload({ variables: { id: value.value } })}
            disabled={isDownloading}
            data-for="download-filename"
            data-tip
          >
            {isDownloading ? (
              <LoadingSpinner size={'16px'} />
            ) : (
              <Icon name="output" ariaLabel="download file" size={['16px']} />
            )}
          </Button>
          <Tooltip
            className="download-success__tooltip"
            questionMark={false}
            place={'bottom'}
            for="download-filename"
          >
            download file
          </Tooltip>
        </>
      ) : null}
    </StyledSelectContainer>
  );
}
