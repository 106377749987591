import styled from 'styled-components';

import ReactSelect from 'react-select';

import { COLORS, PADDINGS, TYPOGRAPHY } from 'Permafrost/index';

import { basicDefaults } from '../commonStyles';

export const StyledSelectContainer = styled.div`
  display: flex;

  .copy-filepath__button {
    margin-left: ${PADDINGS.xs};
  }

  .download-file__button {
    margin-left: 5px;
  }

  .copy-filepath__check {
    color: green;
  }
`;

export const StyledCopyableSelect = styled(ReactSelect)`
  ${basicDefaults};

  // overall component container
  .combobox__control {
    width: 250px;
    border: 1px solid ${COLORS.midFontColor};
    color: ${COLORS.midFontColor};
    background: transparent;
    border-radius: 4px;
    font-size: ${TYPOGRAPHY.fontSize.base};
    border: 1px solid ${COLORS.midFontColor};
    text-align: left;

    &:hover {
      border-color: ${COLORS.lightFontColor};
    }
  }

  .combobox__flex-control {
    width: 100%;
    display: inline-flex;
  }

  // the dropdown list container
  .combobox__menu {
    width: 400px;
    background-color: ${COLORS.clay};
    border: 1px solid ${COLORS.mediumGray};
  }

  // the dropdown list
  .combobox__menu-list {
    border-radius: inherit;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }

  .combobox__flex-option {
    display: flex;
  }

  // the dropdown options
  .combobox__option {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: small;
    white-space: nowrap;
  }

  .combobox__ellipsis {
    ${TYPOGRAPHY.ellipsis}
  }

  // text input placeholder text
  .combobox__placeholder {
    color: ${COLORS.defaultFontColor};
  }

  // selected item text inside input
  .combobox__single-value {
    color: ${COLORS.defaultFontColor};
  }

  .combobox__indicator {
    color: ${COLORS.defaultFontColor};
  }
`;
