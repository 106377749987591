import create, { State, StateCreator, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { produce } from 'immer';
import { undoMiddleware, UndoState } from 'zundo';
import shallow from 'zustand/shallow';

export type Set<StoreState> = (
  cb: (store: StoreState) => void,
  overwrite?: boolean,
  actionName?: string
) => void;
export type Get<StoreState> = () => StoreState;
export type StoreUndoActions = UndoState;

// Immer middleware
const immer = (config: any) => (set: any, get: any, api: any) =>
  config(
    (fn: any, replace?: boolean, actionName?: string) => {
      set(produce(fn), replace, actionName);
    },
    get,
    api
  );

export type UseStore<Store extends State> = UseBoundStore<StoreApi<Store>>;

// TODO: Deprecrate this function and rely on `createTypedStore` instead for all functions (TICKET #: https://indicodata.atlassian.net/browse/UI-990)
export function createStore<T extends State>(store: any): any {
  if (process.env.NODE_ENV === 'development') {
    return create<T>(devtools(immer(store)));
  }

  return create<T>(immer(store));
}

// TODO: Rename to `createStore` once current `createStore` is deprecated (TICKET #: https://indicodata.atlassian.net/browse/UI-990)
export function createTypedStore<T extends State>(
  store: StateCreator<T>
): UseBoundStore<T, StoreApi<T>> {
  if (process.env.NODE_ENV === 'development') {
    return create<T>(devtools(immer(store)));
  }

  return create<T>(immer(store));
}

/**
 * Works just like createStore(), but includes undo/redo functionality.
 *
 * @see {@link https://github.com/charkour/zundo}
 */
export function createStoreWithUndo(
  store: any,
  options?: {
    exclude?: string[];
    historyDepthLimit?: number;
    include?: string[];
  }
): any {
  if (process.env.NODE_ENV === 'development') {
    return create(undoMiddleware(devtools(immer(store)), options));
  }

  return create(undoMiddleware(immer(store), options));
}
export { shallow };
