import React, { useState, useEffect, useMemo, useRef, CSSProperties } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  Column,
  ColumnDef,
  flexRender,
  Row,
} from '@tanstack/react-table';

import {
  CenteredRow,
  StyledBodyTr,
  StyledContainer,
  StyledTable,
  StyledTableContainer,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
} from './table.styles';
import { ActionBar } from './components/ActionBar';
import { Pagination } from './components/Pagination';
import { NoResults } from './components/NoResults';
import { LoadingSpinner } from '../status-indicators/LoadingSpinner/LoadingSpinner';
import { isNil } from 'lodash';

type WithPaginationProps = {
  currentPage: number;
  totalPageCount: number;
  totalRowCount: number;
  paginationLimit?: number;
  nextPage: () => void;
  previousPage: () => void;
  showPagination: true;
};

type WithoutPaginationProps = {
  currentPage?: number;
  totalPageCount?: number;
  totalRowCount?: number;
  paginationLimit?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  showPagination?: false;
};

type PaginationProps = WithPaginationProps | WithoutPaginationProps;

export type Props<T extends object> = {
  data: T[];
  columns: ColumnDef<T & { id: string }>[];
  className?: string;
  isFixed?: boolean;
  TableActions?: React.ComponentType<{ selectedItems: Row<any>[]; unselectRows: () => void }>;
  error?: {
    hasError: boolean;
    errorMessage?: string;
  };
  enableRowSelection?: boolean | ((row: Row<T>) => boolean);
  clearFilters?: () => void;
  hasFiltersApplied?: boolean;
  loading?: boolean;
  defaultPinnedColumns?: string[];
} & PaginationProps;

export function Table<T extends object>({
  columns: defaultColumns,
  data,
  className,
  isFixed = false,
  currentPage,
  totalPageCount,
  totalRowCount,
  paginationLimit = 1000,
  nextPage,
  previousPage,
  TableActions,
  error,
  enableRowSelection = true,
  clearFilters,
  hasFiltersApplied,
  showPagination = true,
  loading = false,
  defaultPinnedColumns,
}: Props<T & { id: string }>) {
  const columns = useMemo<ColumnDef<T & { id: string }>[]>(() => defaultColumns, []);
  const defaultData = useMemo(() => [], []);

  const [rowSelection, setRowSelection] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formattedColumns, setFormattedColumns] = useState<ColumnDef<T & { id: string }>[]>(
    columns
  );

  const thRefs = useRef<Record<string, HTMLTableCellElement | null>>({});

  // Tracks changes to browser width to allow for updating of column widths
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Sets formattedColumns with correct column widths
  useEffect(() => {
    const updatedColumns: ColumnDef<T & { id: string }>[] = [];
    formattedColumns.forEach((column) => {
      updatedColumns.push({
        ...column,
        size: thRefs.current[column.id as keyof typeof thRefs.current]!.offsetWidth,
      });
    });
    setFormattedColumns(updatedColumns);
  }, [data, columns, windowWidth]);

  const table = useReactTable({
    data: data ?? defaultData,
    columns: formattedColumns,
    state: {
      rowSelection,
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
    initialState: {
      columnPinning: {
        left: defaultPinnedColumns,
      },
    },
  });

  const totalRowsOnPage = table.getRowModel().rows.length;
  const hasPaginationProps =
    !isNil(currentPage) &&
    !isNil(totalPageCount) &&
    !isNil(totalRowsOnPage) &&
    !isNil(totalRowCount) &&
    nextPage &&
    previousPage;

  // Sets style attributes for pinned columns
  const getCommonPinningStyles = (column: Column<T & { id: string }>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    return {
      boxShadow: isLastLeftPinnedColumn ? '-4px 0 4px -4px gray inset' : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? 'sticky' : 'initial',
      zIndex: isPinned ? 1 : 0,
    };
  };

  return (
    <StyledContainer $isFixed={isFixed}>
      <StyledTableContainer $isFixed={isFixed}>
        <StyledTable $isFixed={isFixed} className={className} $isLoading={loading}>
          <StyledThead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { column } = header;
                  return (
                    <StyledTh
                      key={header.id}
                      ref={(el: any) => {
                        if (el) thRefs.current[column.id] = el;
                      }}
                      colSpan={header.colSpan}
                      $noPadding={!!header.column.columnDef.meta?.styles?.header?.noPadding}
                      $hasDefinedColumnSize={
                        !!header.column.columnDef.meta?.styles?.definedColumnSize
                      }
                      $width={header.column.getSize()}
                      style={{ ...getCommonPinningStyles(column) }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </StyledTh>
                  );
                })}
              </tr>
            ))}
          </StyledThead>
          <StyledTbody>
            {error?.hasError && !loading ? (
              <StyledBodyTr $isSelected={false}>
                <CenteredRow colSpan={columns.length}>
                  <NoResults
                    clearFilters={clearFilters}
                    hasFiltersApplied={hasFiltersApplied}
                    message={error.errorMessage ?? 'There was an error loading the data.'}
                  />
                </CenteredRow>
              </StyledBodyTr>
            ) : data.length === 0 && !loading ? (
              <StyledBodyTr $isSelected={false}>
                <CenteredRow colSpan={columns.length}>
                  <NoResults
                    clearFilters={clearFilters}
                    hasFiltersApplied={hasFiltersApplied}
                    message="No results found."
                  />
                </CenteredRow>
              </StyledBodyTr>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => (
                  <StyledBodyTr key={row.id} $isSelected={row.getIsSelected()}>
                    {row.getVisibleCells().map((cell) => {
                      const { columnDef } = cell.column;
                      return (
                        <StyledTd
                          key={cell.id}
                          $noPadding={!!columnDef.meta?.styles?.cell?.noPadding}
                          $textAlign={columnDef.meta?.styles?.cell?.textAlign}
                          $hasDefinedColumnSize={!!columnDef.meta?.styles?.definedColumnSize}
                          $width={cell.column.getSize()}
                          style={{ ...getCommonPinningStyles(cell.column) }}
                        >
                          {flexRender(columnDef.cell, cell.getContext())}
                        </StyledTd>
                      );
                    })}
                  </StyledBodyTr>
                ))}
                {loading && (
                  <StyledBodyTr $isSelected={false}>
                    <CenteredRow colSpan={columns.length} $isLoading={loading}>
                      <LoadingSpinner size="36px" />
                    </CenteredRow>
                  </StyledBodyTr>
                )}
              </>
            )}
          </StyledTbody>
        </StyledTable>

        {Object.keys(rowSelection).length > 0 && (
          <ActionBar table={table} isFixed={isFixed} TableActions={TableActions} />
        )}
      </StyledTableContainer>
      {showPagination && hasPaginationProps ? (
        <Pagination
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          totalRowCount={totalRowCount}
          paginationLimit={paginationLimit}
          totalRowsOnPage={totalRowsOnPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      ) : null}
    </StyledContainer>
  );
}
